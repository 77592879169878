<template>
  <div>
    <v-tooltip top>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="error"
          icon
          outlined
          elevation="3"
          class="action"
          v-bind="attrs"
          v-on="on"
          @click="remove()"
        >
          <v-icon small>
            mdi-delete
          </v-icon>
        </v-btn>
      </template>
      <span>Excluir item</span>
    </v-tooltip>
    <confirm-modal ref="confirm" />
  </div>
</template>
<script>
/* tslint:disable */
/* eslint-disable */
import { deleteItem } from "@/utils/deleteDps";
import ConfirmModal from "../ConfirmModal.vue";

export default {
  components: { ConfirmModal },
  name: "DeleteItem",
  props: ["item", "items"],
  methods: {
    async remove() {
      const canRemove = await this.$refs.confirm.open(
        `Excluir ${this.item.name}`,
        "Tem certeza que deseja excluir o beneficiário?",
        { color: "#ff9800" }
      );

      if (canRemove) {
        try {
          this.$store.state.loading = true;
          await deleteItem(this.item.id);

          const index = this.items.findIndex(item => this.item.id === item.id);
          if (index > -1) this.items.splice(index, 1);
          this.$store.commit("showAlert", { type: "success", message: "DPS deletada com sucesso" });
        } catch (error) {
          this.$store.commit("showAlert", {
            type: "error",
            message: "Não foi possível excluir o beneficiário"
          });
        } finally {
          this.$store.state.loading = false;
        }
      }
    }
  }
};
</script>
