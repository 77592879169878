import { del } from '@/utils/api';

// eslint-disable-next-line import/prefer-default-export
export const deleteItem = async (id) => {
  const res = await del({ path: `/dps/delete/${id}` });

  return {
    statusCode: res.statusCode,
    context: res.context,
    message: res.message,
  };
};
